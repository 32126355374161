// Wrest Point Ninja Form IDs
const NINJA_FORMS_CONTACT_PAGE = '1';
const NINJA_FORMS_NEWSLETTER = '3';

// Revinate public api token
const REVINATE_TOKEN = 'f12b711f-99cf-46a2-9c96-fdd2bdfed13b';

/**
 * Get the host of the this script. The host will be used for posting the data.
 */
var getHost = function () {
  return 'contact-api.inguest.com';
};

/**
 * Get and format form data
 * @return {Object}
 * {"tokens":["token1" ...], "contacts":[{"firstname":"myFirst", "email":"my@e.mail"} ...]}
 */
function getFormData(form, token) {
  var inputs = Array.prototype.slice.call(form.getElementsByTagName('input'));
  var selects = Array.prototype.slice.call(form.getElementsByTagName('select'));
  var textareas = Array.prototype.slice.call(
    form.getElementsByTagName('textarea')
  );
  var elements = [].concat(inputs, selects, textareas);
  var data = { tokens: [token], contacts: [] };
  var contact = {};
  var i = 0;
  for (i = 0; i < elements.length; i++) {
    switch (elements[i].nodeName) {
      case 'INPUT':
        if (elements[i].type == 'checkbox') {
          var isChecked = 0;
          if (elements[i].checked) {
            isChecked = 1;
          }

          contact[elements[i].value] = isChecked;
          // #TODO: update this section once checkbox fields have been added to revinate contact properties
          break;
        }
      /* else go to default*/
      case 'SELECT':
        if (elements[i].type == 'select-multiple') {
          var j = 0;
          var selected = [];
          for (j = 0; j < elements[i].options.length; j++) {
            if (elements[i].options[j].selected) {
              selected.push(elements[i].options[j].value);
            }
          }
          contact[elements[i].name] = selected;
          break;
        }
      /* else go to default*/
      default:
        contact[elements[i].name] = elements[i].value;
    }
  }
  data.contacts = [contact];
  return data;
}

function submitContact(postData) {
  var url = '//' + getHost() + '/api/add-contacts-to-lists';
  var xhr = window.XMLHttpRequest
    ? new XMLHttpRequest()
    : new ActiveXObject('Microsoft.XMLHTTP');
  xhr.onreadystatechange = function () {
    if (xhr.readyState == XMLHttpRequest.DONE) {
      if (xhr.status == 200) {
        // console.log('*** CONTACT SUBMITTED ***');
      } else {
        // console.log('*** CONTACT SUBMISSION ERROR ***');
      }
    }
  };

  // post data
  xhr.open('POST', url, true);
  xhr.setRequestHeader('Content-Type', 'text/plain');
  var payload = JSON.stringify(postData);
  xhr.send(payload);
}

function createRevinateContact(form_id) {
  var formSelector = `nf-form-${form_id}-cont`;
  const formElement = $(`#${formSelector} form`)[0];

  var data = getFormData(formElement, REVINATE_TOKEN);
  if (
    typeof data.contacts[0].email == 'undefined' ||
    data.contacts[0].email == ''
  ) {
    return;
  }

  submitContact(data);
}

var ninjaFormCustomController = Marionette.Object.extend({
  initialize: function () {
    this.listenTo(
      Backbone.Radio.channel('forms'),
      'submit:response',
      this.actionSubmit
    );
  },
  actionSubmit: function (response) {
    // Do stuff here.
    var form_id = response.data.form_id;
    if (form_id === NINJA_FORMS_CONTACT_PAGE) {
      // Contact Form Has Been Submitted
      // console.log('Ninja Form - Contact Form - Form Submitted');
      return;
    } else if (form_id === NINJA_FORMS_NEWSLETTER) {
      // Newsletter Form has Been Submitted
      createRevinateContact(form_id);
    }
  },
});

// Instantiate our custom field's controller, defined above.
new ninjaFormCustomController();
