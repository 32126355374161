// Book Now Buttons - GA4 Events
var GA4_VIEW_ITEM_CLASSTAG = 'add_to_cart_button';

// If GA4 metadata variable has values, assume booking page
// If booking page, trigger View Item event
push_view_item_event();

// Add click event for "add to cart" buttons
var elements = document.getElementsByClassName(GA4_VIEW_ITEM_CLASSTAG);
if (elements.length > 0) {
	document.getElementsByClassName(GA4_VIEW_ITEM_CLASSTAG)[0].addEventListener("click", push_add_to_cart_event);
}

function push_add_to_cart_event() {
  if (gtag === undefined) return false;
  // post metadata for GA4 Event
  if (!federalGroup_GA4_meta) return false;

  // Get Room Data
  let item_id = federalGroup_GA4_meta.itemId;
  let item_name = federalGroup_GA4_meta.itemName;
  let price = federalGroup_GA4_meta.price;
 
  const eventData = {
    currency: 'AUD',
    value: price,
    items: [
      {
        item_id,
        item_name
      }
    ]
  }

  
  // Also Push Add to Cart Event
  // console.log('GTAG - Pushing GA4 Event - Add to Cart', eventData);
  gtag("event", "add_to_cart", eventData);
}

function push_view_item_event() {
  if (gtag === undefined) return false;
  // post metadata for GA4 Event
  if (!federalGroup_GA4_meta) return false;

  // Get Room Data
  let item_id = federalGroup_GA4_meta.itemId;
  let item_name = federalGroup_GA4_meta.itemName;
  let price = federalGroup_GA4_meta.price;
 
  // Only send view item events on single room pages
  if (!item_id || !item_name || !price || price === '0' ) return false;

  const eventData = {
    currency: 'AUD',
    value: price,
    items: [
      {
        item_id,
        item_name
      }
    ]
  }

  
  // Also Push Add to Cart Event
  // console.log('GTAG - Pushing GA4 Event - View Item', eventData);
  gtag("event", "view_item", eventData);
}